import React from 'react';
import { Link } from 'gatsby';
import TrabDeGrupo from '@images/domain/homepage/trabalho-de-grupo.jpg';
import styles from './open-courses.module.scss';
import OpenCoursesSection from '@components/domain/courses/open-courses';
import Spacing from '@components/ui/spacing';

const OpenCourses = () => {

  return (
    <div className={styles.container}>

      <Spacing>
        <OpenCoursesSection
          limit={6}
          header="Candidaturas abertas"
          description={[
            "Para mais informações, clica nas páginas dos cursos."
          ]}
        />
      </Spacing>

      <div className={styles.content}>
        <Spacing>
          <Link className={styles.linkToCoursesPage} to={"/cursos/"}>
            Consulta aqui todos os cursos que temos para ti.
          </Link>
        </Spacing>
        <div className={styles.greyArea}></div>
      </div>

      <div className={styles.bottomBanner}>
        <img src={TrabDeGrupo} alt="trabalho de grupo" />
        <div className={styles.yellowSegment}></div>
      </div>

    </div>
  );
};

export default OpenCourses;
